import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import dfSubDays from "date-fns/subDays";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import Delivery from "@/Models/Delivery";
import DeliveryStatusLog from "@/Models/DeliveryStatusLog";

import api from "../Api";

export const QUERY_STATUS = {
  ALL: "ALL",
  PICKUP_SCHEDULED: "PICKUP_SCHEDULED",
  WAREHOUSED: "WAREHOUSED",
  DELIVERY_ALLOCATED: "DELIVERY_ALLOCATED",
  DELIVERY_STARTED: "DELIVERY_STARTED",
  DELIVERY_COMPLETED: "DELIVERY_COMPLETED",
  SENT_BACK_COMPLETED: "SENT_BACK_COMPLETED",
  TYPE_PICKUP: "TYPE_PICKUP",
  TYPE_RETURN: "TYPE_RETURN",
  CANCELED: "CANCELED",
  ACCIDENT: "ACCIDENT",
  SENDER_ADDRESS_ERROR: "SENDER_ADDRESS_ERROR",
  SENDER_ADDRESS_ERROR_CANCELED: "SENDER_ADDRESS_ERROR_CANCELED",
  RECEIVER_ADDRESS_ERROR: "RECEIVER_ADDRESS_ERROR",
  RECEIVER_ADDRESS_ERROR_CANCELED: "RECEIVER_ADDRESS_ERROR_CANCELED",
  DELAYED: "DELAYED",
  POSTPONED: "POSTPONED",
  ADDRESS_NOT_SUPPORTED: "ADDRESS_NOT_SUPPORTED",
  ADDRESS_NOT_SUPPORTED_CANCELED: "ADDRESS_NOT_SUPPORTED_CANCELED",
};

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_BY_ID: "FETCH_BY_ID",
  FETCH_STATUS_LOGS_BY_ID: "FETCH_STATUS_LOGS_BY_ID",
  SET_COUNT: "SET_COUNT",
  SET_DELIVERIES_FOR_WAYBILL: "SET_DELIVERIES_FOR_WAYBILL",
  SET_QUERY: "SET_QUERY",
};

const zonedDateYesterday = dfSubDays(getDateByTimeZone(), 1);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDateYesterday);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_QUERY = {
  dateFrom: initDateFrom,
  dateTo: initDateTo,
  page: 1,
  pageSize: 20,
  status: "ALL",
  query: "",
  region: "",
  filterByLevel: "only-me",
};

const INITIAL_STATE = {
  count: {
    all: 0,
    pickupScheduled: 0,
    deliveryAllocated: 0,
    deliveryStarted: 0,
    deliveryCompleted: 0,
    sentBack: 0,
    typePickup: 0,
    typeReturn: 0,
    canceled: 0,
    accident: 0,
    senderAddressError: 0,
    senderAddressErrorCanceled: 0,
    receiverAddressError: 0,
    receiverAddressErrorCanceled: 0,
    delayed: 0,
    postponed: 0,
    addressNotSupported: 0,
    addressNotSupportedCanceled: 0,
  },
  deliveries: [],
  deliveriesForWaybill: [],
  delivery: {},
  totalCount: 0,
  pageCount: 0,
  query: INITIAL_QUERY,
  statusLogs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveries: action.deliveries,
        totalCount: action.totalCount,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_BY_ID:
      return {
        ...state,
        delivery: action.delivery,
      };
    case CONSTANTS.FETCH_STATUS_LOGS_BY_ID:
      return {
        ...state,
        statusLogs: action.statusLogs,
      };
    case CONSTANTS.SET_COUNT:
      return {
        ...state,
        count: {
          all:
            action.count.pickupScheduled +
            action.count.deliveryAllocated +
            action.count.deliveryStarted +
            action.count.deliveryCompleted +
            action.count.sentBack +
            action.count.typePickup +
            action.count.typeReturn,
          ...action.count,
        },
      };
    case CONSTANTS.SET_DELIVERIES_FOR_WAYBILL:
      return {
        ...state,
        deliveriesForWaybill: action.deliveriesForWaybill,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesStore = () => {
  const { dispatch, state } = useContext(Context);

  const cancel = useCallback(
    async (bookId) => await api.put(`/deliveries/${bookId}/cancel`),
    [api.put],
  );

  const cancelBulk = useCallback(
    async (deliveries) => {
      return await Promise.allSettled(
        deliveries.map((delivery) =>
          api.put(`/deliveries/${delivery.bookId}/cancel`),
        ),
      );
    },
    [api.put],
  );

  const countByStatus = useCallback(async () => {
    Promise.all([
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.PICKUP_SCHEDULED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.DELIVERY_ALLOCATED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.DELIVERY_STARTED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.DELIVERY_COMPLETED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.SENT_BACK_COMPLETED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.TYPE_PICKUP,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.TYPE_RETURN,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.CANCELED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.ACCIDENT,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.SENDER_ADDRESS_ERROR,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.SENDER_ADDRESS_ERROR_CANCELED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.RECEIVER_ADDRESS_ERROR,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.RECEIVER_ADDRESS_ERROR_CANCELED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.DELAYED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.POSTPONED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.ADDRESS_NOT_SUPPORTED,
      }),
      api.get("/deliveries/count", {
        ...state.query,
        status: QUERY_STATUS.ADDRESS_NOT_SUPPORTED_CANCELED,
      }),
    ]).then((results) => {
      dispatch({
        type: CONSTANTS.SET_COUNT,
        count: {
          pickupScheduled: results[0]?.count,
          deliveryAllocated: results[1]?.count,
          deliveryStarted: results[2]?.count,
          deliveryCompleted: results[3]?.count,
          sentBack: results[4]?.count,
          typePickup: results[5]?.count,
          typeReturn: results[6]?.count,
          canceled: results[7]?.count,
          accident: results[8]?.count,
          senderAddressError: results[9]?.count,
          senderAddressErrorCanceled: results[10]?.count,
          receiverAddressError: results[11]?.count,
          receiverAddressErrorCanceled: results[12]?.count,
          delayed: results[13]?.count,
          postponed: results[14]?.count,
          addressNotSupported: results[15]?.count,
          addressNotSupportedCanceled: results[16]?.count,
        },
      });
    });

    return true;
  }, [api.get, dispatch, state.query]);

  const download = useCallback(() => {
    let query = {
      ...state.query,
    };

    // if (selectedDeliveries?.length > 0) {
    //   query.bookIds = selectedDeliveries.map((d) => d.bookId);
    // }

    query.downloadType = "print";
    return api.postForDownload("/deliveries/download", query);

    // return api.get("/deliveries/download", query);
  }, [api.postForDownload, state.query]);

  const fetchAll = useCallback(async () => {
    const { rows, totalCount } = await api.get("/deliveries", state.query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveries: rows.map((d) => new Delivery(d)),
      totalCount,
      pageCount:
        Math.floor(totalCount / state.query.pageSize) +
        (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [dispatch, state.query]);

  const fetchByIdOnTheFly = useCallback(
    async (bookId) => {
      const response = await api.get(`/deliveries/${bookId}`);

      dispatch({
        type: CONSTANTS.FETCH_BY_ID,
        delivery: new Delivery(response),
      });

      return response;
    },
    [dispatch],
  );

  const fetchDoneToday = useCallback(async () => {
    const response = await api.get("/deliveries/done-today");

    return response?.rows.map((d) => new Delivery(d));
  }, [api.get]);

  const fetchNotPrinted = useCallback(async () => {
    const response = await api.get("/deliveries/not-printed", state.query);

    return response?.rows.map((d) => new Delivery(d));
  }, [api.get, state.query]);

  const fetchStatusLogsById = useCallback(
    async (bookId) => {
      const response = await api.get(
        `/deliveries/${bookId}/delivery-status-logs`,
      );

      dispatch({
        type: CONSTANTS.FETCH_STATUS_LOGS_BY_ID,
        statusLogs: response.rows.map((d) => new DeliveryStatusLog(d)),
      });

      return response;
    },
    [dispatch],
  );

  const printedBulk = useCallback(
    async (deliveries) => {
      return await Promise.allSettled(
        deliveries.map((delivery) =>
          api.put(`/deliveries/${delivery.bookId}/print`),
        ),
      );
    },
    [api.put],
  );

  const returnBulk = useCallback(
    async (deliveries) => {
      return await Promise.allSettled(
        deliveries.map((delivery) =>
          api.post(`/deliveries/${delivery.bookId}/returns`),
        ),
      );
    },
    [api.put],
  );

  const setDeliveriesForWaybill = useCallback(
    (_deliveriesForWaybill) => {
      dispatch({
        type: CONSTANTS.SET_DELIVERIES_FOR_WAYBILL,
        deliveriesForWaybill: _deliveriesForWaybill,
      });
    },
    [dispatch],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({ type: CONSTANTS.SET_QUERY, query });
    },
    [dispatch],
  );

  return {
    state,
    cancel,
    cancelBulk,
    countByStatus,
    download,
    fetchAll,
    fetchByIdOnTheFly,
    fetchDoneToday,
    fetchNotPrinted,
    fetchStatusLogsById,
    printedBulk,
    returnBulk,
    setDeliveriesForWaybill,
    setQuery,
  };
};
